export const Styles = {
  FlowCardStyle: {
    actions: {
      margin: '-12px -16px 0 0',
    },
    progressIndicator: {
      margin: '24px 0 0 0',
    },
    cardSx: {
      margin: '12px 0 0',
      fontSize: '11px',
      opacity: '.6',
    },
    cardM: {
      fontSize: '15px',
      opacity: '.8',
    },
    cardDescription: {
      margin: '-12px 0 24px',
    },
    cardLine: {
      margin: '0 0 12px',
      border: '1px solid #f1f1f1',
    },
    listItems: {
      display: 'flex',
      width: '200px',
      padding: '8px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '4px',
      borderRadius: '6px',
      background: '#FFF',
      boxShadow: '0px 4px 12px 0px rgba(52, 58, 63, 0.12), 0px 1px 4px 0px rgba(52, 58, 63, 0.05)',
      right: '8px',
      position: 'absolute',
      top: '100%',
      zIndex: '999',
    },
  },
  FlowStepperStyle: {
    formContainer: (screenHeight) => {
      return {
        height: `${screenHeight}px`,
        overflow: 'auto',
        paddingBottom: '50px',
      };
    },
    successMessage: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '100px',
      marginTop: '100px',
      fontSize: '20px',
    },
    successIcon: {
      marginRight: '5px',
      color: '#0014CC',
    },
    stepper: {
      '& .MuiStepLabel-root .Mui-completed': {
        color: '#0014CC', // circle color (COMPLETED)
      },
      '& .MuiStepLabel-root .Mui-active': {
        color: '#0014CC', // circle color (ACTIVE)
      },
    },
    stepperControls: {
      position: 'absolute',
      right: '32px',
      bottom: '32px',
    },
  },
  FlowButtonStyle: {
    wppButton: {
      position: 'absolute',
      right: '28px',
      top: '128px',
    },
  },
  FlowTabsStyle: {
    tabs: {
      width: '364px',
    },
    box: {
      flexGrow: 1,
      marginTop: '32px',
    },
  },
  FlowViewerStyles: {
    title: {
      padding: '45px 0 0 35px',
      fontSize: '24px',
    },
    toggle: {
      justifyContent: 'flex-end',
      display: 'flex',
    },
    stepper: {
      marginTop: '25px',
      maxWidth: 400,
    },
  },
  FlowViewerCardStyles: {
    box: {
      height: '75px',
      overflow: 'hidden',
      mt: 0,
      textAlign: 'center',
    },
    stepper: {
      '& .MuiStepIcon-root.Mui-completed': {
        color: '#0014CC', // circle color (COMPLETED)
      },
      '& .MuiStepIcon-root.Mui-active': {
        color: '#0014CC', // circle color (ACTIVE)
      },
      margin: '25px 0 0 40px',
      maxWidth: 300,
    },
    buttonBox: {
      height: '50px',
      overflow: 'hidden',
      mt: 2,
      mb: 1,
      textAlign: 'center',
    },
  },
  FlowScoringStyles: {
    title: {
      padding: '45px 0 0 25px',
      fontSize: '24px',
    },
    partnerResponder: {
      padding: '10px 0 0 25px',
      fontSize: '20px',
    },
    gridParent: (height) => {
      return {
        border: '1px solid #DDDDDD',
        height: `${height}px`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      };
    },
    gridDataPro: (height) => {
      return {
        '& .MuiDataGrid-root': {
          height: `${height}px`,
        },
      };
    },
    grid: {
      opacity: '1',
    },
    saveButton: {
      position: 'absolute',
      bottom: '0px',
      right: '0',
      zIndex: '10',
      padding: '0 20px 20px 0',
      display: 'flex',
      flexDirection: 'row',
      gap: '5px',
      marginRight: '12px',
    },
    loadingState: {
      opacity: '.5',
      filter: 'grayscale(1)',
      transition: 'all .3s ease-in-out',
    },
    loadedState: {
      filter: 'none',
      transition: 'all .3s ease-in-out',
    },
    dropdown: {
      margin: '5px 0 5px 25px',
      borderColor: '#EBEAEB',
      display: 'flex',
      justifyContent: 'end',
    },
  },
};
